var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "stat-page__inner-content" }, [
    _c(
      "form",
      {
        staticClass: "stat-table__form",
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.updateTable()
          },
        },
      },
      [
        _c("base-field-date", {
          staticClass: "stat-table__field",
          attrs: { label: "От", "input-id": _vm.getFieldId("from") },
          on: {
            change: function ($event) {
              return _vm.updateTable()
            },
          },
          model: {
            value: _vm.dateFrom,
            callback: function ($$v) {
              _vm.dateFrom = $$v
            },
            expression: "dateFrom",
          },
        }),
        _vm._v(" "),
        _c("base-field-date", {
          staticClass: "stat-table__field",
          attrs: { label: "До", "input-id": _vm.getFieldId("to") },
          on: {
            change: function ($event) {
              return _vm.updateTable()
            },
          },
          model: {
            value: _vm.dateTo,
            callback: function ($$v) {
              _vm.dateTo = $$v
            },
            expression: "dateTo",
          },
        }),
        _vm._v(" "),
        _c("base-field-select-filterable", {
          attrs: {
            label: "Клиент",
            fieldId: _vm.getFieldId("client"),
            values: _vm.getClientList(),
            "current-value": _vm.client,
          },
          on: {
            input: function ($event) {
              return _vm.updateTable()
            },
          },
          model: {
            value: _vm.client,
            callback: function ($$v) {
              _vm.client = $$v
            },
            expression: "client",
          },
        }),
        _vm._v(" "),
        _c("base-field-select", {
          attrs: {
            values: _vm.types,
            "field-id": _vm.getFieldId("types"),
            "current-value": _vm.type,
            label: "Тип",
          },
          on: {
            input: function ($event) {
              return _vm.updateTable()
            },
          },
          model: {
            value: _vm.type,
            callback: function ($$v) {
              _vm.type = $$v
            },
            expression: "type",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "stat-table__content" },
      [
        _vm.rows === null || _vm.rows === undefined
          ? _c("Preloader")
          : _vm.rows.length
          ? _c("div", { staticClass: "stat-table__data" }, [
              _c(
                "ul",
                { staticClass: "stat-table__rows" },
                _vm._l(_vm.rows, function (row) {
                  return _c("li", { staticClass: "stat-table__row" }, [
                    _c("div", { staticClass: "stat-filter-item" }, [
                      _c(
                        "div",
                        { staticClass: "stat-filter-item__count-wrap" },
                        [
                          _c(
                            "div",
                            { staticClass: "stat-filter-item__count-label" },
                            [
                              _vm._v(
                                "\n                Использован\n              "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "stat-filter-item__count" },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(row.total) +
                                  "\n              "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "stat-filter-item__count-label" },
                            [_vm._v("\n                раз\n              ")]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "stat-filter-item__info-wrap" },
                        [
                          _c(
                            "div",
                            { staticClass: "stat-filter-item__info-item" },
                            [
                              _c(
                                "div",
                                { staticClass: "stat-filter-item__info-name" },
                                [
                                  _vm._v(
                                    "\n                  ID\n                "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "stat-filter-item__info-value" },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(row.filter_id) +
                                      "\n                "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "stat-filter-item__info-item" },
                            [
                              _c(
                                "div",
                                { staticClass: "stat-filter-item__info-name" },
                                [
                                  _vm._v(
                                    "\n                  Название\n                "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "stat-filter-item__info-value" },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(row.filter_name) +
                                      "\n                "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "stat-filter-item__info-item" },
                            [
                              _c(
                                "div",
                                { staticClass: "stat-filter-item__info-line" },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm.getType(row.product_type.toString())
                                      ) +
                                      "\n                "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "stat-filter-item__info-item" },
                            [
                              _c(
                                "div",
                                { staticClass: "stat-filter-item__info-line" },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm.getFilterType(row.filter_type)
                                      ) +
                                      "\n                "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "stat-filter-item__info-item" },
                            [
                              _c(
                                "div",
                                { staticClass: "stat-filter-item__info-line" },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(row.filter_access) +
                                      "\n                "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          row.archive
                            ? _c(
                                "div",
                                { staticClass: "stat-filter-item__info-item" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "stat-filter-item__info-line",
                                    },
                                    [
                                      _vm._v(
                                        "\n                  Архивный\n                "
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "ul",
                        { staticClass: "stat-filter-item__filter-data" },
                        _vm._l(row.data, function (item) {
                          return _c(
                            "li",
                            { staticClass: "stat-filter-item__data-item" },
                            [
                              _c(
                                "div",
                                { staticClass: "stat-filter-item__data-name" },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(item.name) +
                                      "\n                "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", {
                                staticClass: "stat-filter-item__data-value",
                                domProps: { innerHTML: _vm._s(item.value) },
                              }),
                            ]
                          )
                        }),
                        0
                      ),
                    ]),
                  ])
                }),
                0
              ),
              _vm._v(" "),
              _c("div", { staticClass: "stat-search-item__pagination_wrap" }, [
                !_vm.isLastPage
                  ? _c(
                      "a",
                      {
                        staticClass:
                          "stat-search-item__pagination button button_primary",
                        attrs: { href: "javascript:void(0);" },
                        on: {
                          click: function ($event) {
                            return _vm.updateTable(true)
                          },
                        },
                      },
                      [_vm._v("\n          Показать еще\n        ")]
                    )
                  : _vm._e(),
              ]),
            ])
          : _c("div", { staticClass: "stat-table__empty" }, [
              _vm._v("\n      Нет данных\n    "),
            ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }