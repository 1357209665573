<template>
  <div class="stat-page__content">
    <ul class="stat-page__tabs tabs tabs_black">
      <li class="tabs__item" :class="{ '_active': currentTab === 'general'}">
        <a href="javascript:void(0);" class="tabs__link" @click="setCurrentTab('general')">
          Общая
        </a>
      </li>
      <li class="tabs__item" :class="{ '_active': currentTab === 'client'}">
        <a href="javascript:void(0);" class="tabs__link" @click="setCurrentTab('client')">
          По клиенту
        </a>
      </li>
      <li class="tabs__item" :class="{ '_active': currentTab === 'activity'}">
        <a href="javascript:void(0);" class="tabs__link" @click="setCurrentTab('activity')">
          Активность
        </a>
      </li>
      <li class="tabs__item" :class="{ '_active': currentTab === 'sessions'}">
        <a href="javascript:void(0);" class="tabs__link" @click="setCurrentTab('sessions')">
          Сессии
        </a>
      </li>
      <li class="tabs__item" :class="{ '_active': currentTab === 'search'}">
        <a href="javascript:void(0);" class="tabs__link" @click="setCurrentTab('search')">
          Поисковые запросы
        </a>
      </li>
      <li class="tabs__item" :class="{ '_active': currentTab === 'filters'}">
        <a href="javascript:void(0);" class="tabs__link" @click="setCurrentTab('filters')">
          Использование фильтров
        </a>
      </li>
    </ul>

    <TheStatClientGeneral v-if="currentTab === 'general'"/>

    <div class="stat-page__links-block" v-else-if="currentTab === 'client' && !currentClient">
      <ul class="stat-page__links-block-list">
        <li class="stat-page__links-block-item" v-for="client in clientList">
          <a href="javascript:void(0);" class="stat-page__links-block-link" @click="setCurrentClient(client.id)">
            {{ client.name }}
          </a>
        </li>
      </ul>
    </div>

    <TheStatClientInstance v-else-if="currentTab === 'client' && currentClient"/>

    <TheStatClientSearch v-else-if="currentTab === 'search'"/>

    <TheStatClientActivity v-else-if="currentTab === 'activity'"/>

    <TheStatClientSessions v-else-if="currentTab === 'sessions'"/>

    <TheStatClientFilters v-else-if="currentTab === 'filters'"/>
  </div>
</template>

<script>
import TheStatClientGeneral from "./TheStatClientGeneral.vue";
import TheStatClientInstance from "./TheStatClientInstance.vue";
import TheStatClientActivity from "./TheStatClientActivity.vue";
import { mapActions, mapState } from "vuex";
import TheStatClientSearch from "./TheStatClientSearch.vue";
import TheStatClientSessions from "./TheStatClientSessions.vue";
import TheStatClientFilters from "./TheStatClientFilters.vue";

export default {
  name: "TheStatClient",
  components: {
    TheStatClientFilters,
    TheStatClientSearch,
    TheStatClientGeneral,
    TheStatClientInstance,
    TheStatClientActivity,
    TheStatClientSessions
  },
  data() {
    return {
      currentTab: 'general'
    }
  },
  computed: {
    ...mapState('statClient', {
      'currentClient': 'currentClient',
      'clientList': 'clientList',
    })
  },
  methods: {
    ...mapActions('statClient', {
      'setCurrentClient': 'setCurrentClient',
      'initStatClientPage': 'initStatClientPage',
    }),
    setCurrentTab(tab) {
      this.currentTab = tab;
    }
  },
  mounted() {
    this.initStatClientPage()
  }
}
</script>
