var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.node.content
    ? _c(
        "div",
        { staticClass: "markup-content", class: { _extend: _vm.getIsExtend } },
        [
          _c("div", { staticClass: "markup-content__inner" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "markup-content__outer" }, [
              _c(
                "div",
                { staticClass: "markup-content__main" },
                [
                  _c("v-select", {
                    staticClass:
                      "markup-content__select markup-content__select_strategy",
                    attrs: {
                      label: "name",
                      reduce: function (option) {
                        return option.value
                      },
                      options: _vm.strategies,
                      clearable: false,
                      searchable: false,
                    },
                    model: {
                      value: _vm.node.content.strategy,
                      callback: function ($$v) {
                        _vm.$set(_vm.node.content, "strategy", $$v)
                      },
                      expression: "node.content.strategy",
                    },
                  }),
                  _vm._v(" "),
                  _vm.isCustom || _vm.isChange
                    ? _c("v-select", {
                        staticClass:
                          "markup-content__select markup-content__select_strategy",
                        attrs: {
                          label: "name",
                          reduce: function (option) {
                            return option.value
                          },
                          options: _vm.sources,
                          clearable: false,
                          searchable: false,
                        },
                        model: {
                          value: _vm.node.content.source,
                          callback: function ($$v) {
                            _vm.$set(_vm.node.content, "source", $$v)
                          },
                          expression: "node.content.source",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isChange
                    ? _c("div", { staticClass: "markup-content__main-title" }, [
                        _vm._v("\n          на\n        "),
                      ])
                    : _c("div", { staticClass: "markup-content__main-title" }, [
                        _vm.isCustom
                          ? _c("span", [_vm._v("по формуле")])
                          : _vm._e(),
                      ]),
                  _vm._v(" "),
                  _vm.isCustom
                    ? _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.node.content.custom,
                            expression: "node.content.custom",
                          },
                        ],
                        staticClass:
                          "markup-content__input markup-content__input_custom",
                        class: { _error: !_vm.formulaIsValid },
                        attrs: { type: "text", autocomplete: "off" },
                        domProps: { value: _vm.node.content.custom },
                        on: {
                          input: [
                            function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.node.content,
                                "custom",
                                $event.target.value
                              )
                            },
                            _vm.mathValidate,
                          ],
                        },
                      })
                    : _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.node.content.value,
                            expression: "node.content.value",
                          },
                        ],
                        staticClass: "markup-content__input",
                        attrs: { type: "text", autocomplete: "off" },
                        domProps: { value: _vm.node.content.value },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.node.content,
                              "value",
                              $event.target.value
                            )
                          },
                        },
                      }),
                  _vm._v(" "),
                  _vm.isChange
                    ? _c("v-select", {
                        staticClass:
                          "markup-content__select markup-content__select_base",
                        attrs: {
                          label: "name",
                          reduce: function (option) {
                            return option.value
                          },
                          options: [
                            { name: "%", value: "percent" },
                            { name: "руб", value: "money" },
                          ],
                          clearable: false,
                          searchable: false,
                        },
                        model: {
                          value: _vm.node.content.base,
                          callback: function ($$v) {
                            _vm.$set(_vm.node.content, "base", $$v)
                          },
                          expression: "node.content.base",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "markup-content__main" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.node.content.gteRRC,
                      expression: "node.content.gteRRC",
                    },
                  ],
                  staticClass:
                    "markup-content__input markup-content__input_checkbox",
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.node.content.gteRRC)
                      ? _vm._i(_vm.node.content.gteRRC, null) > -1
                      : _vm.node.content.gteRRC,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.node.content.gteRRC,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.node.content,
                              "gteRRC",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.node.content,
                              "gteRRC",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.node.content, "gteRRC", $$c)
                      }
                    },
                  },
                }),
                _vm._v(" "),
                _c("label", [_vm._v("\n          Не ниже РРЦ\n        ")]),
                _vm._v(" "),
                _vm.getIsExtend
                  ? _c("div", { staticClass: "markup-content__main-field" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.node.content.isShares,
                            expression: "node.content.isShares",
                          },
                        ],
                        staticClass:
                          "markup-content__input markup-content__input_checkbox",
                        attrs: { type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(_vm.node.content.isShares)
                            ? _vm._i(_vm.node.content.isShares, null) > -1
                            : _vm.node.content.isShares,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.node.content.isShares,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.node.content,
                                    "isShares",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.node.content,
                                    "isShares",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.node.content, "isShares", $$c)
                            }
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("label", [
                        _vm._v("\n            Оплата в доли\n          "),
                      ]),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _vm.getIsExtend
                ? _c("div", { staticClass: "markup-content__extend" }, [
                    _c("div", { staticClass: "markup-content__field" }, [
                      _c("label", [
                        _vm._v("\n            Размер комплекта\n          "),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.node.content.kit,
                            expression: "node.content.kit",
                          },
                        ],
                        staticClass: "markup-content__input",
                        attrs: { type: "text", autocomplete: "off" },
                        domProps: { value: _vm.node.content.kit },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.node.content,
                              "kit",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "markup-content__field" }, [
                      _c("label", [
                        _vm._v("\n            Мин. процент маржи\n          "),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.node.content.marginality,
                            expression: "node.content.marginality",
                          },
                        ],
                        staticClass: "markup-content__input",
                        attrs: { type: "text", autocomplete: "off" },
                        domProps: { value: _vm.node.content.marginality },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.node.content,
                              "marginality",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "markup-content__field" }, [
                      _c("label", [
                        _vm._v(
                          "\n            Процент от цены конкурента\n          "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.node.content.competitor_percent,
                            expression: "node.content.competitor_percent",
                          },
                        ],
                        staticClass: "markup-content__input",
                        attrs: { type: "text", autocomplete: "off" },
                        domProps: {
                          value: _vm.node.content.competitor_percent,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.node.content,
                              "competitor_percent",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "markup-content__field" }, [
                      _c("label", [
                        _vm._v("\n            Процент скидки\n          "),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.node.content.discount_percent,
                            expression: "node.content.discount_percent",
                          },
                        ],
                        staticClass: "markup-content__input",
                        attrs: { type: "text", autocomplete: "off" },
                        domProps: { value: _vm.node.content.discount_percent },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.node.content,
                              "discount_percent",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "markup-content__than-block" }, [
      _c("span", { staticClass: "markup-content__than-text" }, [
        _vm._v("\n          То\n      "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }