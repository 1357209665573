<template>
  <div class="stat-page__inner-content">
    <form class="stat-table__form" @submit.prevent="updateTable()">
      <base-field-date
          :label="'От'"
          :input-id="getFieldId('from')"
          @change="updateTable()"
          class="stat-table__field"
          v-model="dateFrom"
      />
      <base-field-date
          :label="'До'"
          :input-id="getFieldId('to')"
          @change="updateTable()"
          class="stat-table__field"
          v-model="dateTo"
      />
      <base-field-select-filterable
          :label="'Клиент'"
          :fieldId="getFieldId('client')"
          :values="getClientList()"
          v-model="client"
          :current-value="client"
          @input="updateTable()"
      />
      <base-field-select
          :values="types"
          :field-id="getFieldId('types')"
          v-model="type"
          :current-value="type"
          :label="'Тип'"
          @input="updateTable()"
      />
    </form>

    <div class="stat-table__content">
      <Preloader v-if="rows === null || rows === undefined"/>

      <div class="stat-table__data" v-else-if="rows.length">
        <ul  class="stat-table__rows">
          <li v-for="row in rows" class="stat-table__row">
            <div class="stat-filter-item">
              <div class="stat-filter-item__count-wrap">
                <div class="stat-filter-item__count-label">
                  Использован
                </div>
                <div class="stat-filter-item__count">
                  {{ row.total }}
                </div>
                <div class="stat-filter-item__count-label">
                  раз
                </div>
              </div>

              <div class="stat-filter-item__info-wrap">
                <div class="stat-filter-item__info-item">
                  <div class="stat-filter-item__info-name">
                    ID
                  </div>
                  <div class="stat-filter-item__info-value">
                    {{ row.filter_id }}
                  </div>
                </div>
                <div class="stat-filter-item__info-item">
                  <div class="stat-filter-item__info-name">
                    Название
                  </div>
                  <div class="stat-filter-item__info-value">
                    {{ row.filter_name}}
                  </div>
                </div>
                <div class="stat-filter-item__info-item">
                  <div class="stat-filter-item__info-line">
                    {{ getType(row.product_type.toString()) }}
                  </div>
                </div>
                <div class="stat-filter-item__info-item">
                  <div class="stat-filter-item__info-line">
                    {{ getFilterType(row.filter_type) }}
                  </div>
                </div>
                <div class="stat-filter-item__info-item">
                  <div class="stat-filter-item__info-line">
                    {{ row.filter_access }}
                  </div>
                </div>
                <div class="stat-filter-item__info-item" v-if="row.archive">
                  <div class="stat-filter-item__info-line">
                    Архивный
                  </div>
                </div>
              </div>

              <ul class="stat-filter-item__filter-data">
                <li class="stat-filter-item__data-item" v-for="item in row.data">
                  <div class="stat-filter-item__data-name">
                    {{ item.name }}
                  </div>
                  <div class="stat-filter-item__data-value" v-html="item.value"></div>
                </li>
              </ul>
            </div>
          </li>
        </ul>

        <div class="stat-search-item__pagination_wrap">
          <a href="javascript:void(0);" class="stat-search-item__pagination button button_primary"
             @click="updateTable(true)" v-if="!isLastPage">
            Показать еще
          </a>
        </div>
      </div>

      <div v-else class="stat-table__empty">
        Нет данных
      </div>
    </div>

  </div>
</template>

<script>

import BaseFieldDate from "../parts/fields/BaseFieldDate.vue";
import getLastMonthRange from "../../helpers/dates";
import BaseFieldSelectFilterable from "../parts/fields/BaseFieldSelectFilterable.vue";
import {mapActions, mapState} from "vuex";
import BaseFieldSelect from "../parts/fields/BaseFieldSelect.vue";
import Preloader from "../parts/Preloader.vue";

export default {
  name: 'TheStatClientFilters',
  components: {Preloader, BaseFieldSelect, BaseFieldSelectFilterable, BaseFieldDate},
  data() {
    return {
      dateFrom: null,
      dateTo: null,
      tableKey: 'client_filters_table',
      client: null,
      isLastPage: false,
      rows: null,
      type: null,
      page: 1,
      pageSize: 50,
      types: [
        { name: 'Легковые шины', value: '10' },
        { name: 'Грузовые шины', value: '20' },
        { name: 'Мото шины', value: '30' },
        { name: 'Легковые диски', value: '40' },
        { name: 'Грузовые диски', value: '50' },
        { name: 'Прочее', value: '60' },
      ],
      filterTypes: {
        param: 'По параметрам',
        auto: 'По автомобилю'
      }
    }
  },
  computed: {
    ...mapState('statClient', {
      'clientList': 'clientList',
    }),
  },
  methods: {
    ...mapActions('stat', {
      'loadStatData':'loadStatData'
    }),
    ...mapActions('statClient', {
      'initStatClientPage': 'initStatClientPage',
    }),
    getFieldId(name) {
      return `${this.tableKey}__${name}`;
    },
    getClientList() {
      const list = [];
      this.clientList.forEach(item => {
        list.push({ name: `${item.id}: ${item.name}`, value: item.id.toString()})
      });

      return list;
    },
    getType(type) {
      const result = this.types.find((item) => item.value === type);
      return result ? result.name : '-';
    },
    getFilterType(type) {
      return this.filterTypes[type] || '-';
    },
    async updateTable(isNextPage) {
      if (isNextPage) {
        this.page++;
      } else {
        this.page = 1;
        this.isLastPage = false;
      }

      const rows = await this.loadStatData({
        statDataKey: this.tableKey,
        params: {
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
          client: this.client,
          page: this.page,
          pageSize: this.pageSize,
          type: this.type,
        }
      });

      if (isNextPage) {
        if (rows.length === 0) {
          this.isLastPage = true;
        }
        this.rows = this.rows.concat(rows);
      } else {
        this.rows = rows
      }
    }
  },
  mounted() {
    this.initStatClientPage();
    const range = getLastMonthRange();
    this.dateFrom = range.from;
    this.dateTo = range.to;
    this.updateTable();
  }
}
</script>

